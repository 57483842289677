<app-base-page [pageActions]="pageActions" [pageActionsCommandData]="pageActionsCommandData" [cardContainer]="showCard">
  @if (!fromPopup) {
    <ng-container page-header>
      @if (showTitle) {
        <h4 class="m-0">{{ itemId }} Sections</h4>
      }
      @if (!showTitle) {
        <div></div>
      }

      <div class="flex flex-row gap-3 mt-2">
        <!-- <app-drop-down-input [label]="'Status'" [placeholder]="'Select Status'" [name]="'status'"
      [optionLabel]="'label'" [items]="listOfStatuses" [optionValue]="'value'" [control]="statusFilter"
    [badgeView]="true"></app-drop-down-input> -->
        @if (showActions) {
          <app-button [action]="fixOrdersAction"></app-button>
        }

        <app-button [action]="toggleBadgesAction"></app-button>

        @if (showExpandButton) {
          <app-button [action]="this.expandedState ? collapseAllAction : expandAllAction"></app-button>
        }
        <!-- <app-button [action]="collapseAllAction"></app-button> -->
        @if (showActions) {
          <app-button [action]="addCitationAction"></app-button>
          <app-button [action]="applyTemplate"></app-button>
        }
        @if (showPrintAction) {
          <app-button [action]="exportTreePdf"></app-button>
        }
      </div>
    </ng-container>
  }
  @if (!fromPopup) {
    <p-tree
      [value]="formattedNodes"
      [loading]="isLoading"
      selectionMode="single"
      [(selection)]="selectedFile"
      [class]="fromPopup ? 'primary-bg-800' : ''"
      class="description-tree"
      styleClass="border-none p-0 w-full"
      [draggableNodes]="showActions"
      [droppableNodes]="showActions"
      draggableScope="self"
      droppableScope="self"
      (onNodeDrop)="onDrop($event)"
    >
      <ng-template let-node pTemplate="default">
        <div class="flex flex-row gap-1 tree-button-hover-container">
          <div
            [class]="
              'flex-1 flex align-items-center hide-children-hover' +
              (linkedSectionsForm?.controls?.[node?.data?.code] && linkedSections?.length > 0
                ? ' justify-content-between'
                : '')
            "
          >
            <p
              class="m-0 flex align-items-center justify-content-center"
              [class]="node?.data?.level == 0 ? 'text-xl' : node?.data?.level == 1 ? '' : 'text-sm'"
            >
              {{ node?.data?.index + ' ' }}{{ node?.data | translateField: 'subject' }}
            </p>
            <app-field-translations-button
              [options]="{
                label: 'modules.framework.policy.policyBodySection.fields.subject.label' | translate: 'Title',
                placeholder:
                  'modules.framework.policy.policyBodySection.fields.subject.placeholder' | translate: 'Enter Title',
                textFieldType: textType,
                fieldKey: 'subject',
                itemCode: node?.data?.code,
                formData: node?.data?.subject,
                dataService: requestService,
                mappingService: mappingService,
                options: null,
              }"
              class="hidden-item"
            ></app-field-translations-button>
            @if (linkedSectionsForm?.controls?.[node?.data?.code] && linkedSections?.length > 0) {
              <p-treeSelect
                class="md:w-20rem w-full"
                containerStyleClass="w-full"
                [formControl]="$any(linkedSectionsForm?.controls?.[node?.data?.code])"
                [options]="treeLinkedSections"
                placeholder="Select Item"
                [appendTo]="'body'"
              >
                <ng-template let-node pTemplate="default">
                  <div class="w-full flex gap-4 justify-content-between align-items-center">
                    <span class="h-fit flex align-items-center">
                      @if (node?.data?.code) {
                        <app-badge-item
                          class="mr-2"
                          [styleClass]="'text-xs'"
                          [status]="node?.data?.recordStatus || 'gray'"
                          [text]="node?.data?.code"
                        ></app-badge-item>
                      }
                      <app-badge-view [data]="node?.data?.type"></app-badge-view>
                      <p>{{ node | translateField: 'label' }}</p>
                    </span>
                  </div>
                </ng-template>
              </p-treeSelect>
            }
            @if (node?.data?.editMode == 'view' && showActions) {
              <span class="pi pi-arrows-alt tree-button-hover ml-2 cursor-move"></span>
            }
            @if (node?.data?.editMode == 'view' && showActions) {
              <app-button class="ml-2 tree-button-hover" [action]="editNodeAction" [commandData]="node"></app-button>
            }
            @if (node?.data?.editMode == 'view' && showActions) {
              <app-button class="ml-2 tree-button-hover" [action]="addNodeAction" [commandData]="node"></app-button>
            }
            @if (node?.data?.editMode == 'view' && showActions && node?.data?.recordStatus != 'DELETED') {
              <app-button class="ml-2 tree-button-hover" [action]="deleteNodeAction" [commandData]="node"></app-button>
            }
            <!-- @if (node?.data?.editMode != 'view' && showActions) {
              <app-button
                class="ml-2 tree-button-hover"
                [action]="cancelEditNodeAction"
                [commandData]="node"
              ></app-button>
            } -->
            <!-- <app-button *ngIf="node?.data?.editMode" class="ml-2" [action]="saveEditNodeAction" [commandData]="node"></app-button>
        <app-button *ngIf="node?.data?.editMode" class="ml-2" [action]="cancelEditNodeAction" [commandData]="node"></app-button> -->
          </div>
        </div>
        @if (badgesToggled) {
          <p class="m-0 text-lg flex gap-1 align-items-start justify-content-start">
            <app-badge-item
              [displayText]="false"
              [smallMode]="true"
              [showIcon]="true"
              [status]="node?.data?.recordStatus"
              [text]="node?.data?.code"
            >
              <app-code-navigation
                [sendRequest]="false"
                [showRecordStatus]="false"
                [showLabel]="false"
                [data]="node?.data?.code"
                [color]="'inherit'"
              ></app-code-navigation>
            </app-badge-item>
            <app-badge-view [smallMode]="true" [data]="node?.data?.type"></app-badge-view>
          </p>
        }
        <!-- <app-drop-down-input *ngIf="linkedSectionsForm?.controls?.[node?.data?.code] && linkedSections?.length > 0"
    [label]="''"
    [placeholder]="'Select Item'"
    [name]="'type'"
    [optionLabel]="'subject'"
    [items]="linkedSections"
    [optionValue]="'code'"
    [control]="linkedSectionsForm?.controls?.[node?.data?.code]"
    [viewMode]="'create'"
    [appendTo]="'body'"
  ></app-drop-down-input> -->

        @if (node?.expanded || node?.data?.editMode != 'view') {
          <ng-container>
            <!-- <app-code-navigation class="text-sm" [data]="node?.data?.citationType"></app-code-navigation> -->
            <!-- <app-badge-item [displayText]="false" [showIcon]="true" [status]="node?.data?.recordStatus"
      [text]="node?.data?.citationType">
      <app-code-navigation [data]="node?.data?.citationType" [color]="'inherit'"></app-code-navigation>
    </app-badge-item> -->
            @if (node?.data?.body && node?.data?.editMode == 'view') {
              <div class="mt-1 text-gray-200">
                <app-html-text-view [data]="node?.data | translateField: 'body'"></app-html-text-view>
              </div>
            }
            <!-- <ng-container *ngIf="node?.data?.notes">
    <p-divider class="w-full"></p-divider>
    <div class="w-full font-light">
      <p class="mb-0">Notes:</p>
      <div class="pl-2">
        <app-html-text-view [data]="node?.data?.notes"></app-html-text-view>
      </div>
    </div>
  </ng-container> -->
          </ng-container>
          @if (node?.data?.editMode == 'edit') {
            <app-policy-body-section-item-form
              (formSubmitSave)="onRowEditSave($event, node?.data?.code)"
              (cancelEvent)="onRowEditCancel($event, node?.data?.code)"
              [formData]="node?.data"
              [mode]="'edit'"
              [showCancel]="true"
            ></app-policy-body-section-item-form>
          }
          @if (node?.data?.editMode == 'create') {
            <app-policy-body-section-item-form
              (formSubmitSave)="onRowEditSave($event, node?.data?.code)"
              (cancelEvent)="onRowEditCancel($event, node?.data?.code)"
              [formData]="$any({ parent: node?.data?.code, create: true })"
              [mode]="'create'"
              [showCancel]="true"
            ></app-policy-body-section-item-form>
          }
        }
      </ng-template>
    </p-tree>
  } @else {
    <app-policy-body-section-basic-tree
      [tree]="formattedNodes"
      [policyData]="policyData"
    ></app-policy-body-section-basic-tree>
  }
  <div class="flex flex-row-reverse gap-2">
    @if (fromPopup && !isLoading) {
      <app-button [action]="printPdfAction"></app-button>
    }
    @if (showRelink) {
      <app-button [action]="relinkButtonAction"></app-button>
    }
  </div>
</app-base-page>
