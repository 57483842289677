import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem, PolicyDto } from '@shared/classes';

@Component({
  selector: 'app-policy-body-section-basic-tree',
  templateUrl: './policy-body-section-basic-tree.component.html',
  styleUrl: './policy-body-section-basic-tree.component.scss',
})
export class PolicyBodySectionBasicTreeComponent extends BaseViewItem implements OnInit {
  @Input() tree;
  @Input() policyData: PolicyDto = null;

  ngOnInit(): void {}
}
