<ul id="tree" style="list-style-type: none">
  <div class="flex flex-row justify-content between">
    <h2>{{ policyData.name }}</h2>
    <h2>{{ policyData.description }}</h2>
  </div>
  <ng-container *ngTemplateOutlet="recursiveList; context: { nodes: tree }"></ng-container>
</ul>

<ng-template #recursiveList let-nodes="nodes">
  <ng-container *ngFor="let node of nodes">
    <li style="list-style-type: none; margin-bottom: 4px">
      <div>
        <p
          style="margin: 0"
          [style]="
            node?.data?.level == 0 ? { fontSize: '1.25rem' } : node?.data?.level == 1 ? {} : { fontSize: '0.875rem' }
          "
        >
          {{ node?.data?.index + ' ' }}{{ node?.data?.subject }}
        </p>
        <p style="margin: 0" class="m-0 text-lg flex gap-1 align-items-start justify-content-start">
          <app-badge-item
            [displayText]="false"
            [smallMode]="true"
            [showIcon]="true"
            [status]="node?.data?.recordStatus"
            [text]="node?.data?.code"
          >
            <app-code-navigation
              [sendRequest]="false"
              [showRecordStatus]="false"
              [showLabel]="false"
              [data]="node?.data?.code"
              [color]="'inherit'"
            ></app-code-navigation>
          </app-badge-item>
          <app-badge-view [smallMode]="true" [data]="node?.data?.type"></app-badge-view>
        </p>
        @if (node?.data?.body) {
          <div class="mt-1 text-gray-200">
            <app-html-text-view [data]="node?.data?.body"></app-html-text-view>
          </div>
        }
      </div>

      <ul *ngIf="node.children" style="list-style-type: none">
        <ng-container *ngTemplateOutlet="recursiveList; context: { nodes: node.children }"></ng-container>
      </ul>
    </li>
  </ng-container>
</ng-template>
