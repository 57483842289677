import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseForm, ModuleKeywords, PolicyBodyTemplateDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-policy-body-template-selector',
  templateUrl: './policy-body-template-selector.component.html',
  styleUrl: './policy-body-template-selector.component.scss'
})
export class PolicyBodyTemplateSelectorComponent extends BaseForm<PolicyBodyTemplateDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.PolicyBodyTemplate);
  }
  policyBodyTemplateControl = new FormControl(null);


  ngOnInit(): void { }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat({ ...this.policyBodyTemplateControl.getRawValue() })
      : this.getChangedFormValues({ ...this.policyBodyTemplateControl.getRawValue() }).updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({

    });
  }


}
